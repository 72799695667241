import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductInfo from './ProductInfo';

function App() {
    const [product, setProduct] = useState(null);
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchProductData = () => {
        setLoading(true); // Yuklanish jarayonini ko'rsatish uchun
        fetch('https://intpers.chinastore.uz/api/v1/product/get/json/info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': '0a725aa3-48ba-48bd-b021-e8c845781ee5'
            },
            body: JSON.stringify({ url })
        })
            .then(response => response.json())
            .then(data => {
                setProduct(data);
                setLoading(false); // Yuklanish tugadi
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
                setLoading(false); // Xato holatda ham yuklanish tugadi
            });
    };

    return (
        <div className="container my-4">
            <h1 className="text-center">Product Info Fetcher</h1>
            <div className="mb-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter product URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
                <button className="btn btn-primary mt-2" onClick={fetchProductData}>
                    Fetch Product Info
                </button>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                product && <ProductInfo product={product} />
            )}
        </div>
    );
}

export default App;
