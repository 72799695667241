import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function ProductInfo({product}) {
    // product.data yoki product.data.description bo'lmasa, descriptionni bo'sh qiymat bilan boshlaymiz
    const [description, setDescription] = useState(product?.data?.description || '');

    useEffect(() => {
        // Product data o'zgarganda description qiymatini yangilab boramiz
        if (product && product.data && product.data.description) {
            setDescription(product.data.description);
        }
    }, [product]);

    if (!product || !product.data) {
        return <p>Loading...</p>;
    }

    const handleDescriptionChange = (value) => {
        setDescription(value);
    };

    return (
        <div className="container my-4">
            <h1 className="text-center mb-4">{product.data.title}</h1>
            <div className="row">
                <div className="col-md-6">
                    <img src={product.data.images[0]} alt="Product" className="img-fluid mb-3"/>
                    <p><strong>Price (UZS):</strong> {product.data.price}</p>
                    <p><strong>Price (CNY):</strong> {product.data.cnyPrice}</p>
                    <a href={product.data.productUrl} target="_blank" rel="noopener noreferrer"
                       className="btn btn-primary mt-2">
                        View Product
                    </a>
                </div>
                <div className="col-md-6">
                    <h4>Available Sizes</h4>
                    <ul className="list-group mb-3">
                        {(product.data.params?.[0]?.values || []).length > 0 ? (
                            product.data.params[0].values.map((size, index) => (
                                <li key={index} className="list-group-item">
                                    {size.name} - {size.price} UZS  {size.name} - {size.cnyPrice} CNY
                                </li>
                            ))
                        ) : (
                            <li className="list-group-item">O'lchamlar mavjud emas</li>
                        )}
                    </ul>

                    <h4>Available Colors</h4>
                    <div className="d-flex flex-wrap">
                        {(product.data.params?.[0]?.values || []).length > 0 ? (product.data.params[1].values.map((color, index) => (
                                <div key={index} className="card m-2" style={{width: '100px'}}>
                                    <img src={color.image} alt={color.name} className="card-img-top"/>
                                    <div className="card-body p-2">
                                        <p className="card-text text-center">{color.name}</p>
                                    </div>
                                </div>))
                        ) : (
                            <li className="list-group-item">O'lchamlar mavjud emas</li>
                        )}
                    </div>
                </div>
            </div>

            {/* Properties jadvali */}
            <div className="my-4">
                <h4>Properties</h4>
                <table className="table table-bordered">
                    <tbody>
                    {product.data.properties.map((property, index) => (
                        <tr key={index}>
                            <td><strong>{property.name}</strong></td>
                            <td>{property.value}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {/* Description */}
            <div className="my-4">
                <h4>Product Description</h4>
                <ReactQuill
                    value={description}
                    onChange={handleDescriptionChange}
                    theme="snow"
                />
                <div className="mt-4">
                    <h5>Preview:</h5>
                    <div dangerouslySetInnerHTML={{__html: description}}></div>
                </div>
            </div>

            {/* Video */}
            <div className="my-4">
                <h4>Product Video</h4>
                <video controls className="w-100">
                    <source src={product.data.videos[0]} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
}

export default ProductInfo;
